import * as React from "react"
import Layout from '../../components/layout'
import { Link } from "gatsby"
import techforgood from '../../images/techforgood.png'

export default function Favorites() {
  return(
    <Layout>

      <Link to="/" state={{ item: 'writing' }} className="flex space-x-2 items-center hover:underline mb-7">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
        </svg>
        <div >
          back to all writing
        </div>
      </Link>

      <div className="text-2xl font-bold mb-2">
        Reflections on "Tech for Good"
      </div>

      <div className="text-gray-600 mb-6">
        11/2/2023
      </div>

      <div className="space-y-4">
        <p className="italic">
            Written as a blog post for <a href="https://61040-fa23.github.io" className="underline">6.1040</a>:
        </p>
        <p>
            As an 11-6 major, I’ve often used the terms “public interest technology,” “civic tech,” and “urban tech” when describing my interests to others. A couple of years ago, I came across an essay on Twitter entitled <a href="https://relay.substack.com/p/tech-for-good-isnt-a-thing" className="underline">"Tech for good isn’t a thing."</a> In the essay, the author, Alix Dunn, focuses on separating the intent behind ethical or socially productive technology from its actual implementation and consequences, which are ultimately determined by structures of governance and accountability. Dunn argues that the phrase “tech for good” is misleading and incomprehensive because it doesn’t consider companies’ revenue structures and governance strategies in addition to just their missions. Dunn gives the following advice for creating tech that’s actually "good:"
        </p>
        <img src={techforgood} className=""/>
        <p>
            Before entering MIT, I tried founding my own small startup, a political engagement and advocacy platform called <a href="https://grassrootsgov.org" className="underline">GrassrootsGov</a>. Although I tried to define our organization’s mission clearly and thought deeply about the potential impacts of the platform on a variety of stakeholders, I still struggled with determining appropriate—and scalable—systems of governance and content moderation.
        </p>
        <p>
            I was also very concerned about creating a band-aid solution that gave myself and users pats on the back without actually addressing the underlying problems that I was trying to solve (political disempowerment and resulting apathy). Similarly, I also struggled with the ethics of building a private company that was addressing a public concern, possibly undermining the necessity of public solutions for public problems. In “Tech for good isn’t a thing,” Dunn wrote the following sentence: “And if you find yourself inventing solutions that seek to fill gaps in a failed system, you should probably carry out advocacy to fix that broken system while you manufacture band-aids.” An interesting consideration in general, however, is that if your company is advocating to eliminate the problem that it’s profiting off of, this could result in an inherent misalignment of incentives. As I prepare to start my career post-MIT, these are questions that I'm still contemplating.
        </p>
      </div>
      
    </Layout>
  )
}